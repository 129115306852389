<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <v-row justify="end">
                    <v-col align-self="center">
                        <v-text-field rounded filled hide-details v-model="search" label="Suche"></v-text-field>
                    </v-col>
                    <v-col cols="auto" align-self="center">
                        <v-btn large rounded color="primary" @click="create_mitarbeiter = true">
                            <v-icon class="mr-2">mdi-plus</v-icon>
                            Mitarbeiter erstellen
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="text-center primary--text">
                <v-data-table
                    :headers="headers"
                    :items="mitarbeiter"
                    item-key="name"
                    class="elevation-0"
                    fixed-header
                    :search="search"
                    locale="de-DE"
                    :footer-props="{
                        itemsPerPageText: 'Zeilen pro Seite:',
                    }"
                    >
                    <template v-slot:no-data>
                        Es wurden keine Vereinsmitarbeiter gefunden.
                    </template>
                    <template v-slot:item.name="{ index, item }">
                        <a class="font-weight-bold" @click="open_link(`/verein/mitarbeiter/${item.id}`)">{{ item.vorname }} {{item.nachname}}</a>
                    </template>
                    <template v-slot:item.rechte="{ index, item }">
                        {{item.rechte && item.rechte.admin ? 'Vereins-Administrator' : ''}}
                        {{item.rechte && item.rechte.abteilungsleiter ? 'Abteilungsleiter' : ''}}
                        {{item.rechte && item.rechte.trainer ? 'Trainer' : ''}}
                    </template>
                    </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="create_mitarbeiter" :content-class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : ''" :fullscreen="$vuetify.breakpoint.smAndDown" :max-width="$vuetify.breakpoint.smAndDown ? '' : $vuetify.breakpoint.lgAndUp ? '900' : '700'">
            <v-card color="#CFD8DC" :class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : ''">
                <v-toolbar color="primary" dark :style="$vuetify.breakpoint.smAndDown ? '' : `position: fixed; width: ${$vuetify.breakpoint.lgAndUp ? '900' : '700'}px;`">
                    <v-toolbar-title>Mitarbeiter erstellen</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="create_mitarbeiter = false">
                        <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
                    </v-btn>
                </v-toolbar>
                <v-row justify="center" class="pa-4">
                    <v-col cols="12" :style="$vuetify.breakpoint.smAndDown ? '' : 'margin-top: 80px;'">
                        <v-row justify="center">
                            <v-col cols="12" md="6">
                                <v-text-field rounded filled v-model="trainer.vorname" label="Vorname"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field rounded filled v-model="trainer.nachname" label="Nachname"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="mx-3">
                                <v-text-field rounded filled v-model="trainer.funktion" label="Funktion (z.B. Trainer)"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field rounded filled v-model="trainer.email" label="E-Mail"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field rounded filled v-model="trainer.emailconfirm" label="E-Mail bestätigen"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center">
                                <v-btn outlined rounded large @click="fileupload = true">
                                    <font-awesome-icon :icon="trainer.img ? 'fa-regular fa-check' : 'fa-regular fa-image-polaroid'" size="lg" class="mr-2" />
                                    {{ trainer.img ? 'Bild ändern' : 'Bild hochladen' }}
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-divider class="my-4"></v-divider>
                                <h2 class="text-center">Rolle auswählen</h2>
                            </v-col>
                            <v-col cols="6" md="4" class="h-100" align-self="center">
                                <v-card class="rounded-xl pa-3 h-100 text-center" :color="trainer.rechte.admin ? 'success' : '#74709540'" @click="trainer.rechte = rechte[0]">
                                    <h3 class="text-center pa-2">Vereins-Administrator</h3>
                                    <v-divider></v-divider>
                                    <v-card-text class="font-weight-bold">
                                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Mitarbeiter verwalten</span><br>
                                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Sportangebote verwalten</span><br>
                                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Teilnehmer verwalten</span><br>
                                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Vereinsinfo bearbeiten</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6" md="4" class="h-100" align-self="center">
                                <v-card class="rounded-xl pa-3 h-100 text-center" :color="trainer.rechte.abteilungsleiter ? 'success' : '#74709540'" @click="trainer.rechte = rechte[1]">
                                    <h3 class="text-center pa-2">Abteilungsleiter</h3>
                                    <v-divider></v-divider>
                                    <v-card-text class="font-weight-bold">
                                        <span>Zugriff auf zugewiesene Sportarten:</span><br>
                                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Trainer verwalten</span><br>
                                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Sportangebote verwalten</span><br>
                                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Teilnehmer verwalten</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6" md="4" class="h-100" align-self="center">
                                <v-card class="rounded-xl pa-3 h-100 text-center" :color="trainer.rechte.trainer ? 'success' : '#74709540'" @click="trainer.rechte = rechte[2]">
                                    <h3 class="text-center pa-2">Trainer/Übungsleiter</h3>
                                    <v-divider></v-divider>
                                    <v-card-text class="font-weight-bold">
                                        <span>Zugriff auf zugewiesene Sportangebote:</span><br>
                                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Sportangebote verwalten</span><br>
                                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Teilnehmer verwalten</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="8" class="text-center" v-if="trainer.rechte.abteilungsleiter">
                                <v-select multiple rounded filled label="Sportarten" :items="sportarten.all" item-text="name" item-value="name" v-model="trainer.rechte.sportarten"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-divider class="my-4"></v-divider>
                                <h2 class="text-center">Kontaktdaten</h2>
                                <v-row justify="center" class="pt-4">
                                    <v-col cols="12" lg="6">
                                        <v-text-field rounded filled v-model="trainer.email" label="E-Mail"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <v-text-field rounded filled v-model="trainer.telefon" label="Telefon"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider class="my-4"></v-divider>
                                <h2 class="text-center">Datenschutz</h2>
                                <v-row justify="center">
                                    <v-col cols="12" md="4">
                                        <v-checkbox color="primary" label="E-Mail darf veröffentlicht werden" v-model="trainer.public_data.email"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-checkbox color="primary" label="Telefonnummer darf veröffentlicht werden" v-model="trainer.public_data.telefon"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-checkbox color="primary" label="Telefonnummer kann auf WhatsApp kontaktiert werden" v-model="trainer.public_data.whatsapp"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <v-divider class="my-4"></v-divider>
                                <v-btn rounded :loading="creating" :disabled="!valid" large color="primary" @click="create()">
                                    Mitarbeiter erstellen
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
            <v-dialog
                v-model="fileupload"
                :max-width="$vuetify.breakpoint.mdAndUp ? '1200' : 'none'"
                :overlay-opacity="
                $vuetify.breakpoint.mdAndUp ? 0.85 : undefined
                "
                :hide-overlay="$vuetify.breakpoint.mdAndUp ? false : true"
                :transition="
                $vuetify.breakpoint.mdAndUp
                    ? 'dialog-transition'
                    : 'dialog-bottom-transition'
                "
                :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true"
                :width="$vuetify.breakpoint.mdAndUp ? 'auto' : '100vw'"
                content-class="rounded-xl"
            >
                <v-card class="rounded-xl">
                    <v-toolbar color="primary" dark>
                        <v-card-title>
                            Bild hochladen
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row justify="center" class="my-4">
                        <v-col :cols="imageData ? 12 : 10">
                            <v-image-input
                            v-model="imageData"
                            :image-quality="0.5"
                            clearable
                            accept="image/*"
                            :hideActions="imageData ? false : true"
                            :imageWidth="500"
                            :imageMinScaling="'cover'"
                            :imageHeight="500"
                            :full-width="true"
                            image-format="jpg"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center"
                            v-if="!imageData"
                        >
                            Für eine gute Darstellung wähle bitte ein Bild mit
                            mindestens 600 x 800 Pixeln.
                        </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions right>
                        <v-btn
                            color="primary"
                            right
                            rounded
                            :loading="uploading"
                            @click="upload()"
                        >
                            Speichern
                        </v-btn>
                        <v-btn
                            color="secondary"
                            right
                            rounded
                            @click="closeDialog()"
                        >
                            Abbrechen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>
    </v-container>
</template>

<script>
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, onSnapshot, query, where } from '@firebase/firestore'
import { mapGetters } from 'vuex'
import router from '../../../router'
import { getDownloadURL, getStorage, ref, uploadString } from '@firebase/storage'

export default {
    name: 'Mitarbeiter',
    data(){
        return {
            fileupload: false,
            uploading: false,
            imageData: '',
            creating: false,
            create_mitarbeiter: false,
            rechte: [
                {
                    admin: true,
                },
                {
                    admin: false,
                    sportarten: [],
                    abteilungsleiter: true,
                    trainer: false,
                },
                {
                    admin: false,
                    abteilungsleiter: false,
                    trainer: true,
                }
            ],
            trainer: {
                vorname: '',
                nachname: '',
                email: '',
                emailconfirm: '',
                rechte: '',
                img: '',
                imgRef: '',
                telefon: '',
                funktion: '',
                public_data: {
                    telefon: false,
                    email: false,
                    whatsapp: false
                }
            },
            mitarbeiter: [],
            headers: [
                {
                    text: 'Name',
                    value: 'name',
                    sortable: true,
                },
                {
                    text: 'E-Mail',
                    value: 'email',
                    sortable: true,
                },
                {
                    text: 'Rechte',
                    value: 'rechte',
                }
            ],
            search: ''
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            sportarten: 'sportarten'
        }),
        valid(){
            if(this.trainer.vorname && this.trainer.nachname && this.trainer.email && this.trainer.email == this.trainer.emailconfirm && this.trainer.rechte){
                return true
            }
            return false
        }
    },
    mounted(){
        if(!this.user.data?.rechte?.admin){
            router.push('/verein')
        }
        else {
            this.load_user()
        }
    },
    methods: {
        async load_user(){
            if(this.user.data?.verein?.id){
                onSnapshot(
                    query(
                        collection(getFirestore(), 'User'), 
                        where('verein', '==', this.user.data.verein.id)
                    ),
                    mitarbeiter => {
                        this.creating = false
                        this.create_mitarbeiter = false
                        this.trainer = {
                            vorname: '',
                            nachname: '',
                            email: '',
                            emailconfirm: '',
                            rechte: '',
                            img: '',
                            imgRef: '',
                            telefon: '',
                            funktion: '',
                            public_data: {
                                telefon: false,
                                email: false,
                                whatsapp: false
                            }
                        }
                        this.fileupload = false
                        this.imageData = ''
                        this.uploading = false
                        this.mitarbeiter = []
                        mitarbeiter.forEach(person => {
                            if(person.id != this.user.uid){
                                this.mitarbeiter.push(Object.assign({id: person.id}, person.data()))
                            }
                        })
                })
            }
        },
        closeDialog() {
            this.fileupload = false
            this.uploading = false
            this.imageData = ''
        },
        async upload() {
            const datum = new Date();
            const storage = getStorage();
            this.uploading = true
            if (this.imageData) {
                const image = this.imageData.replace('data:image/png;base64,', '')
                const storageRef = ref(storage, '/user/'+datum.getTime()+'.jpg');
                uploadString(storageRef, image, 'base64').then(snapshot => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    this.trainer.img = downloadURL
                    this.trainer.imgRef = '/user/'+datum.getTime()+'.jpg'
                    this.uploading = false
                    this.fileupload = false
                });
                })
            }
        },
        open_link(link){
            router.push(link)
        },
        async create(){
            this.creating = true
            await addDoc(collection(getFirestore(), 'trainer'), {
                vorname: this.trainer.vorname,
                nachname: this.trainer.nachname,
                rechte: this.trainer.rechte,
                img: this.trainer.img || 'https://finde-deinen-sport.app/img/user_icon.png',
                imgRef: this.trainer.imgRef || '',
                email: this.trainer.email,
                verein: this.user.data.verein.id,
                vereinsname: this.user.data.verein.name,
                public_data: this.trainer.public_data,
                telefon: this.trainer.telefon || '',
                funktion: this.trainer.funktion || ''
            })
        }
    } 
}
</script>

<style>
    .h-100 {
        height: 100% !important;
    }
</style>